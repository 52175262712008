import React from "react";
 
 
import {SiteLogoDark, SiteLogo} from './Frontapi.js';

 
  


  const GetDynamicImagebaseurl = async(param) => { 

 if(window.location.origin === 'http://localhost:3001'){
 	  	return  'http://localhost/academicgyan/assets/images/' ;

  }else if(window.location.origin === 'http://localhost'){

  return   'http://localhost/assets/images/' ;
}else if(window.location.origin === 'https://academicgyan.com'){

	  return   'https://www.academicgyan.com/assets/images/' ;

}else if(window.location.origin === 'https://www.academicgyan.com'){

	  return   'https://www.academicgyan.com/assets/images/' ;

} 


  }

const DynamicImagesUrl = await GetDynamicImagebaseurl();


const Getpostimage=async(thumbnail)=>{

 
	
	//return  (DynamicImagesUrl +'posts/'+thumbnail )
		var imageexists = 0;
		const img = new Image();
		img.src = DynamicImagesUrl +'posts/'+thumbnail;


	if ( parseInt(img.width)  == parseInt(0)  ) {
 
	} else {
			imageexists = 1;
 
	}


	 


 
		if(imageexists == 1){
		return  (DynamicImagesUrl +'posts/'+thumbnail+'?v='+parseInt(Math.random()  * 1000000) )
		}else{
		return SiteLogo
		}

	 
 

}

const Getuserimage=async(profilepicture)=>{

 var imageexists = 0;
		const img = new Image();
		img.src = DynamicImagesUrl +'users/'+profilepicture;

   	
	if ( parseInt(img.width)  == parseInt(0)  ) {
 
	} else {
			imageexists = 1;
 
	}


 		if(imageexists == 1){

		return  (DynamicImagesUrl +'users/'+profilepicture+'?v='+parseInt(Math.random()  * 1000000) )
		}else{
		return SiteLogo
		}


 
}


const Getprofileimage=async(profilepicture)=>{


var imageexists = 0;
		const img = new Image();
		img.src = DynamicImagesUrl +'profile/'+profilepicture;

		
	if ( parseInt(img.width)  == parseInt(0)  ) {
 
	} else {
			imageexists = 1;
 
	}

	


		if(imageexists == 1){
		return  (DynamicImagesUrl +'profile/'+profilepicture+'?v='+parseInt(Math.random()  * 1000000) )
		}else{
		return SiteLogo
		}


 
}



export { Getpostimage ,Getuserimage,Getprofileimage,DynamicImagesUrl}